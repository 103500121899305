export const patientPI: any = {
  patMainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  footer: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu Consumer - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  patFooterMenu: "Footer Navigation Menu Consumer GCSO",
  patFirstPara: "HCP Validate Healthcare Provider - Retina Spain",
  backToTopGTM: "Back to top - Pre Index",
  footerClassName: "pre-index-footer"
}
