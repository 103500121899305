import React, { useEffect } from 'react';
import { graphql, navigate, withPrefix } from 'gatsby';
import { Cookies } from 'react-cookie-consent';
import { Layout, HTMLRenderer } from '@retina-packages/retina-theme-bootstrap';
import { PreGateWrapper } from './styles';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { patientPI } from './constants';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';

const PreIndexTemplate = (props: Props) => {
  useEffect(() => {
    document.getElementById('decline')?.addEventListener("click", function(e: Event){
      e.preventDefault();
      navigate("/");
    })
    document.getElementById('accept')?.addEventListener("click", function(e: Event) {
      e.preventDefault();
      Cookies.set('hcpvalidated', 1)
      const params = new URLSearchParams(window.location.search)
      let redirectUrl = params.get('redirect') as string
      if (!redirectUrl || redirectUrl === withPrefix(retinaConfig.preIndexUrl)) {
        redirectUrl = withPrefix(retinaConfig.hcpHomeUrl)
      }
      window.location.href = redirectUrl
    })
  }, [])
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso

  const pgContextsPI = props.pageContext
  const htmlStaticFilesPI = {
    nonSVGImages: pgContextsPI.nonSVGImages,
    svgMediaImages: pgContextsPI.svgMediaImages,
    allMediaDocument: pgContextsPI.documents
  }
  const { footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: "", siteFooterMenu: patientPI.patFooterMenu });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const hcpHeadingDataPI = fullHTMLDataBuilder({ blocks, title: patientPI.patFirstPara });

  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientPI.footer
  const exitPopupTitle = patientPI.exitPopup
  const hcpSwitcherTitle = ""
  const topNavTitle = ""
  const {
    footerText,
    exitData,
  }: {
    footerText: string;
    exitData: exitPopDataType;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const siteLogos: any = deriveMultiLogo({ blocks, title: patientPI.siteLogo })

  return (
    <PreGateWrapper>
      {props.pageContext !== null && props.pageContext.metaInfo !== null && (
        <MetaTagContainer metaData={props.pageContext.metaInfo} />
      )}
        <Layout
          title={"siteTitle"}
          location={props.location}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          audience={audience}
          footerData={footerText}
          mdata={footerMenu}
          exitPopData={exitData}
          siteLogos={siteLogos}
          gtmBackToTopLabel={patientPI.backToTopGTM}
          backToTopContent={retinaConfig.backToTopContent}
          staticMediaFiles={htmlStaticFilesPI}
          hcpValidate={retinaConfig.hcpValidate}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          preIndexUrl={retinaConfig.preIndexUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          footerClassName={patientPI.footerClassName}
          {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
        >
          <div className='pre-gate-main'>
            <HTMLRenderer html={hcpHeadingDataPI} data={htmlStaticFilesPI} tagName='div' className='pre-index-wrapper' />
          </div>
        </Layout>
    </PreGateWrapper>
  )
}

export default PreIndexTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...PreIndexPageQuery
      }
    }
  }
`
